<template>
  <div>
    <nav>
      <div class="logo">
        <router-link to="/">
          <img src="../assets/images/logo.svg" alt="Logo" />
        </router-link>
      </div>
      <div class="mobile hamburger-dropdown">
        <div
          @click="toggleOpen"
          :class="{ opened: isActive }"
          class="hamburger-lines"
        >
          <span class="line line1"></span>
          <span class="line line2"></span>
          <span class="line line3"></span>
        </div>
      </div>
      <div class="navigation pc">
        <ul>
          <li>
            <button @click="openCreatePageModal" class="create-button">
              Vytvoriť stránku
            </button>
          </li>
          <li><router-link to="/web-system">Domov</router-link></li>
          <li><router-link to="/webtracking">Webtracking</router-link></li>
          <li>
            <button @click="logout" class="logout-button">Odhlásiť sa</button>
          </li>
        </ul>
      </div>
    </nav>

    <!-- Mobilné menu -->
    <div v-if="isActive" class="mobile-navigation">
      <ul>
        <li>
          <button @click="openCreatePageModal" class="create-button">
            Vytvoriť stránku
          </button>
        </li>
        <li><router-link to="/web-system">Domov</router-link></li>
        <li><router-link to="/webtracking">Webtracking</router-link></li>
        <li>
          <button @click="logout" class="logout-button">Odhlásiť sa</button>
        </li>
      </ul>
    </div>

    <!-- Modal na vytvorenie stránky -->
    <el-dialog
      title="Vytvoriť stránku"
      v-model="isCreatePageModalVisible"
      width="90%"
      :center="true"
      @close="closeCreatePageModal"
      @open="centerDialog"
    >
      <el-form>
        <el-form-item label="Názov stránky" prop="title">
          <el-input
            v-model="pageTitle"
            placeholder="Názov novej podstránky"
            class="title-input"
          ></el-input>
        </el-form-item>
        <el-form-item label="Popis stránky" prop="description">
          <el-input
            v-model="pageDescription"
            type="textarea"
            placeholder="Popis novej podstránky"
            class="description-input"
          ></el-input>
        </el-form-item>
        <el-form-item label="Telefónne číslo" prop="phone_number">
          <el-input
            v-model="phoneNumber"
            placeholder="Zadajte telefónne číslo"
          ></el-input>
        </el-form-item>
        <el-form-item label="Meno" prop="first_name">
          <el-input v-model="firstName" placeholder="Zadajte meno"></el-input>
        </el-form-item>
        <el-form-item label="Priezvisko" prop="last_name">
          <el-input
            v-model="lastName"
            placeholder="Zadajte priezvisko"
          ></el-input>
        </el-form-item>
        <el-form-item label="E-mail" prop="email">
          <el-input v-model="email" placeholder="Zadajte e-mail"></el-input>
        </el-form-item>
      </el-form>
      <template v-slot:footer>
        <el-button @click="closeCreatePageModal">Zrušiť</el-button>
        <el-button type="primary" class="create-button" @click="createPage"
          >Vytvoriť</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      isActive: false,
      isCreatePageModalVisible: false,
      pageTitle: "",
      pageDescription: "",
      phoneNumber: "",
      firstName: "",
      lastName: "",
      email: "",
    };
  },
  methods: {
    toggleOpen() {
      this.isActive = !this.isActive;
    },
    openCreatePageModal() {
      this.isCreatePageModalVisible = true;
    },
    closeCreatePageModal() {
      this.isCreatePageModalVisible = false;
      this.pageTitle = "";
      this.pageDescription = "";
      this.phoneNumber = "";
      this.firstName = "";
      this.lastName = "";
      this.email = "";
    },
    centerDialog() {
      const dialogWrapper = document.querySelector(".el-overlay-dialog");
      if (dialogWrapper) {
        dialogWrapper.style.display = "flex";
        dialogWrapper.style.justifyContent = "center";
        dialogWrapper.style.alignItems = "center";
        dialogWrapper.style.minHeight = "100vh";
      }
    },
    async createPage() {
      if (!this.pageTitle) {
        this.$root.showAlert("Názov podstránky nemôže byť prázdny.");
        return;
      }

      const token = localStorage.getItem("token");
      const formattedDescription = this.pageDescription
        ? this.pageDescription.replaceAll("\n", "//n")
        : null;

      try {
        await axios.post(
          `https://api.hg-solution.de/api/sites`,
          {
            title: this.pageTitle,
            description: formattedDescription,
            phone_number: this.phoneNumber || null,
            first_name: this.firstName || null,
            last_name: this.lastName || null,
            email: this.email || null,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        this.$root.showAlert("Úspešne si nahral údaje do databázy.");
        this.closeCreatePageModal();
        location.reload();
      } catch (error) {
        this.$root.showAlert("Nastala chyba pri vytváraní podstránky.");
        console.error(
          "Chyba pri vytváraní podstránky:",
          error.response?.data || error
        );
      }
    },
    async logout() {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Chýba autentifikačný token.");
        }

        await axios.post("https://api.hg-solution.de/api/logout", null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        localStorage.removeItem("authToken");
        this.$router.push("/login");
      } catch (error) {
        console.error(
          "Odhlásenie zlyhalo:",
          error.response?.data || error.message
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// IMPORT COLORS
@import "@/assets/sass/_colors.scss";

nav,
.mobile-navigation {
  background: $grey-color;
  padding: 1.5em 3%;
  z-index: 100;
  width: 96%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  position: fixed;

  .logo {
    width: 10%;
    display: flex;
    align-items: center;
  }

  .navigation {
    width: 80%;
  }

  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style: none;
    gap: 2%;
    font-size: 1.1em;
    padding-left: 0;
    align-items: center;
    margin-right: 10%;

    li a {
      text-decoration: none;
      color: $text-invert-color;
      position: relative;
    }
  }

  .language {
    width: 10%;
    color: $text-invert-color;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10%;
    text-decoration: none;
    cursor: pointer;

    img {
      width: 1.1em;
    }
  }
}

.mobile-navigation {
  width: 100%;
  margin-top: 7em;
  ul {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 1em 10%;

    li {
      line-height: 2em;
    }
    li:not(:first-child) {
      border-top: #575757 0.1em solid;
    }
  }

  .language {
    width: 100%;
    gap: 0.5em;
    justify-content: center;
  }
}
.router-link-active {
  font-weight: 700;
}

.navigation .router-link-active:after,
.mobile-navigation .router-link-active:after {
  content: "";
  position: absolute;
  width: 70%;
  display: block;
  height: 2px;
  background-color: rgba(34, 91, 80, 0.82);
  bottom: -5px;
  animation: active 1s cubic-bezier(0, 0.52, 0.58, 1);
}

.logout-button {
  background-color: $green-color-button;
  color: $text-invert-color;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: lighten($green-color-button, 10%);
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
}

.create-button {
  background-color: $green-color-button; /* Rovnaká farba ako logout */
  color: $text-invert-color;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: lighten($green-color-button, 10%);
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
}

.logout-mobile {
  color: $green-color-button;
  cursor: pointer;
  padding: 10px;
}

@keyframes active {
  from {
    width: 0%;
  }
  to {
    width: 70%;
  }
}

@media screen and (max-width: 1460px) {
  nav ul,
  .language {
    font-size: 1em;
  }
}

@media screen and (max-width: 1330px) {
  nav ul,
  .language {
    font-size: 0.9em;
  }
}

@media screen and (min-width: 1200px) {
  .pc {
    display: block;
  }

  .mobile {
    display: none;
    position: relative;
  }
}

@media screen and (max-width: 1200px) {
  nav {
    justify-content: space-between;
    flex-direction: row;
    padding: 1.5em 8%;
    width: 84%;
    .logo {
      width: 45%;
      justify-content: flex-start;
    }

    .hamburger-dropdown {
      justify-content: flex-end;
      width: 45%;
      display: flex;
      cursor: pointer;
    }

    .navigation {
      justify-content: center;
      width: 100%;
      display: flex;
      font-size: 120%;
    }

    .language {
      color: $text-color;
      font-weight: 800;
      font-size: 1.1em;
      filter: brightness(0);
      width: 20%;
    }
  }
  .pc {
    display: none !important;
  }

  .mobile {
    display: flex;
    justify-content: flex-end;
  }

  .opened {
    display: flex !important;
  }
  .mobile-navigation li {
    margin-top: 0.5rem;
  }
}

/* Lines hamburger */
.hamburger-lines {
  display: block;
  height: 26px;
  width: 32px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hamburger-lines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #0e2431;
  transition: transform 0.4s ease-in-out;
}

.hamburger-lines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.hamburger-lines .line2 {
  transition: transform 0.2s ease-in-out;
}

.opened.hamburger-lines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.opened.hamburger-lines .line1 {
  transform: rotate(45deg);
}

.opened.hamburger-lines .line2 {
  transform: scaleY(0);
}

.opened.hamburger-lines .line3 {
  transform: rotate(-45deg);
}
</style>
